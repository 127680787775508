/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import TextField from '@material-ui/core/TextField';
import Image from '@common_image';
import TextField from '@common_textfield';
import Typography from '@common_typography';
import React from 'react';
// import DesktopInstallApp from '@core_modules/theme/components/custom-install-popup/desktop';
import Button from '@common_button';
import {
 features, modules, custDataNameCookie, expiredToken,
} from '@config';
import ProductCompareIcon from '@core_modules/catalog/plugins/ProductCompare';
import Autocomplete from '@core_modules/theme/components/header/desktop/components/autocomplete';
import OptionAutocomplete from '@core_modules/theme/components/header/desktop/components/autocomplete/view';
import Menu from '@core_modules/theme/components/header/desktop/components/v3/mcategory';
import { CountdownTimer2 } from '@core_modules/theme/components/header/desktop/plugins/CountdownTimer/index';
import OtpInput from '@core_modules/theme/components/header/desktop/plugins/OtpInput';
import { getLoginInfo } from '@helper_auth';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import NotificationBell from '@plugin_notificationbell';
import ShoppingBagIcon from '@plugin_shoppingbag';
import Icon from '@theme/icons/icon';
import firebase from 'firebase/app';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FiLogIn, FiUser } from 'react-icons/fi';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { HiOutlineHeart } from 'react-icons/hi2';
import { ImFacebook, ImGoogle, ImPhone } from 'react-icons/im';
import {
    getCustomerCartId,
    mergeCart as mutationMergeCart,
} from '@core_modules/login/services/graphql';
import { setCookies } from '@helper_cookies';
import Cookies from 'js-cookie';
import { getCartId, setCartId } from '@helper_cartid';
import { assignCompareListToCustomer } from '@core_modules/productcompare/service/graphql';
import { localCompare } from '@services/graphql/schema/local';
import { priceVar } from '@root/core/services/graphql/cache';
import { useReactiveVar } from '@apollo/client';
import { useRouter } from 'next/router';

const TopMenu = dynamic(() => import('@core_modules/theme/components/header/desktop/components/v3/mtop'), { ssr: false });

const ViewTopNavigation = (props) => {
    const {
        formik,
        formikOtp,
        handleSubmit,
        storeConfig,
        handleSearch,
        searchByClick,
        setValue,
        value,
        data,
        loading,
        t,
        customer,
        handleLogout,
        app_cookies,
        showGlobalPromo,
        socialLoginMethodData,
        socialLoginMethodLoading,
        handleSocialLogin,
        vesMenuConfig,
        isRequestOtpSent,
        expiredTime,
        setExpiredTime,
        otpTimeoutTime,
        anchorEl,
        setAnchorEl,
        errStatus,
        setErrStatus,
        errMsg,
        setDisabled,
    } = props;

    const isLogin = getLoginInfo();
    const [firebaseLoaded, setFirebaseLoaded] = React.useState(false);
    const [google, setGoogle] = React.useState('');
    const [facebook, setFacebook] = React.useState('');
    const [loginMethod, setLoginMethod] = React.useState('default');
    const [otpValue, setOtpValue] = React.useState('');
    const [scroll, setScroll] = React.useState(false);

    const router = useRouter();

    // cache price
    const cachePrice = useReactiveVar(priceVar);
    const expired = storeConfig?.oauth_access_token_lifetime_customer
        ? new Date(Date.now() + parseInt(storeConfig?.oauth_access_token_lifetime_customer, 10) * 3600000)
        : expiredToken;

    const onChangeOtp = (val_) => {
        setOtpValue(val_);
    };

    React.useEffect(() => {
        if (otpValue.length === 4 && !otpValue.includes(' ')) {
            window.backdropLoader(true);
            const vars = {
                username: formikOtp.values.phonenumber,
                otp: otpValue,
            };
            handleSubmit('otp', vars);
        }
    }, [otpValue]);

    const handlePopup = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const signInOptions = [];

    if (features.firebase.config.apiKey !== '' && firebase && firebase.auth && socialLoginMethodData && socialLoginMethodData.length > 0) {
        for (let idx = 0; idx < socialLoginMethodData.length; idx += 1) {
            const code = socialLoginMethodData[idx];
            if (code.match(/google/i) && firebase.auth.GoogleAuthProvider && firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
            }

            if (code.match(/facebook/i) && firebase.auth.FacebookAuthProvider && firebase.auth.FacebookAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.FacebookAuthProvider.PROVIDER_ID);
            }

            if (code.match(/twitter/i) && firebase.auth.TwitterAuthProvider && firebase.auth.TwitterAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.TwitterAuthProvider.PROVIDER_ID);
            }

            if (code.match(/github/i) && firebase.auth.GithubAuthProvider && firebase.auth.GithubAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.GithubAuthProvider.PROVIDER_ID);
            }

            if (code.match(/email/i) && firebase.auth.EmailAuthProvider && firebase.auth.EmailAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.EmailAuthProvider.PROVIDER_ID);
            }
        }
    }

    // cart data

    const [mergeCart] = mutationMergeCart();
    const [mergeCompareProduct, { client }] = assignCompareListToCustomer();

    const cartData = getCustomerCartId({
        skip: !isLogin,
    });

    let cartId = '';
    if (typeof window !== 'undefined') {
        cartId = getCartId();
    }

    const uiConfig = {
        signInFlow: 'popup',
        signInOptions,
        callbacks: {
            signInSuccessWithAuthResult: () => false,
        },
    };

    const switchLogin = () => {
        setLoginMethod(loginMethod === 'otp' ? 'default' : 'otp');
        setErrStatus(false);
    };

    const clickGoogle = () => {
        google[0].click();
        handleSocialLogin();
    };

    const clickFacebook = () => {
        facebook[0].click();
        handleSocialLogin();
    };

    React.useEffect(() => {
        if (features.firebase.config.apiKey === '') {
            setFirebaseLoaded(false);
        } else {
            setFirebaseLoaded(true);
        }
    }, [firebaseLoaded]);

    React.useEffect(() => {
        setGoogle(document.getElementsByClassName('firebaseui-idp-google'));
        setFacebook(document.getElementsByClassName('firebaseui-idp-facebook'));
    }, []);

    React.useEffect(() => {
        if (typeof window !== 'undefined' && storeConfig && storeConfig.pwa && storeConfig.pwa.enabler_sticky_header) {
            const headerInner = document.getElementById('header-inner');
            const checkScrollTop = () => {
                if (headerInner) {
                    if (window.scrollY > 10) {
                        headerInner.classList.add('header-small');
                    } else {
                        headerInner.classList.remove('header-small');
                    }

                    if (!scroll && window.scrollY > 10) {
                        setScroll(true);
                    } else if (scroll && window.scrollY < 10) {
                        setScroll(false);
                    }
                }
            };
            window.addEventListener('scroll', checkScrollTop);
        }
    }, [scroll]);

    React.useEffect(() => {
        if (cartData.data && customer.customer && cartData.data.customerCart && cartData.data.customerCart && cartData.data.customerCart.id) {
            Cookies.set(custDataNameCookie, {
                email: customer.customer.email,
                firstname: customer.customer.firstname,
                lastname: customer.customer.lastname,
                customer_group: customer.customer.customer_group,
                phonenumber: customer.customer.phonenumber,
                is_phonenumber_valid: customer.customer.is_phonenumber_valid,
            });
            const isCustHasAddress = !!customer.customer.default_shipping && !!customer.customer.default_shipping;
            setCookies('is_cust_address', isCustHasAddress);
            const custCartId = cartData.data.customerCart.id;
            if (customer?.customer?.compare_list?.uid) {
                mergeCompareProduct({
                    variables: {
                        uid: customer.customer.compare_list.uid,
                    },
                    request: 'internal',
                    skip: customer.customer.compare_list.uid,
                })
                    .then((res) => {
                        setCookies('uid_product_compare', res.data.assignCompareListToCustomer.compare_list.uid);
                        client.writeQuery({
                            query: localCompare,
                            data: {
                                item_count: res.data.assignCompareListToCustomer.compare_list.item_count,
                                items: res.data.assignCompareListToCustomer.compare_list.items,
                            },
                        });
                    })
                    .catch(() => {
                        //
                    });
            }
            if (cartId === '' || !cartId) {
                setCartId(custCartId, expired);
                if (Object.keys(cachePrice).length > 0) {
                    priceVar({});
                }
                setDisabled(false);
                window.backdropLoader(false);
                window.toastMessage({ open: true, variant: 'success', text: t('login:success') });
                if (router.route.includes('/customer/account/create')) {
                    router.push('/');
                } else {
                    router.reload('/');
                }
            } else if (cartId !== custCartId) {
                mergeCart({
                    variables: {
                        sourceCartId: cartId,
                        destionationCartId: custCartId,
                    },
                })
                    .then(async (res) => {
                        await setCartId(res.data.mergeCarts.id, expired);
                        await setDisabled(false);
                        window.backdropLoader(false);
                        window.toastMessage({ open: true, variant: 'success', text: t('login:success') });
                        setTimeout(() => {
                            if (router.route.includes('/customer/account/create')) {
                                router.push('/');
                            } else {
                                router.reload('/');
                            }
                        }, 1500);
                    })
                    .catch(() => {});
            }
        }

        if (cartData.error || customer.error) {
            window.backdropLoader(false);
            window.toastMessage({
                open: true,
                variant: 'error',
                text: t('login:failed'),
            });
        }
    }, [cartData, customer]);

    const renderDefaultLogin = () => (
        <>
            <div className="popup-top">
                <form onSubmit={formik.handleSubmit}>
                    <div className={`${formik.errors.username ? 'error' : ''} input-wrapper`}>
                        <FiUser />
                        <TextField
                            id="username"
                            name="username"
                            placeholder="User"
                            type="email"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handlBlur}
                        />
                    </div>

                    <div className={`${formik.errors.password ? 'error' : ''} input-wrapper`}>
                        <HiOutlineLockClosed />
                        <TextField
                            id="password"
                            name="password"
                            placeholder="Password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handlBlur}
                        />
                    </div>
                    {formik.touched.username && formik.errors.username && (
                        <Typography className="error-msg" variant="p" type="regular" color="primary">
                            {formik.errors.username}
                        </Typography>
                    )}
                    {formik.touched.password && formik.errors.password && (
                        <Typography className="error-msg" variant="p" type="regular" color="primary">
                            {formik.errors.password}
                        </Typography>
                    )}

                    <Link href="/customer/account/forgotpassword" prefetch={false}>
                        <Button type="button" className="forgot-password" variant="text">
                            {t('common:header:forgotpassword')}
                        </Button>
                    </Link>

                    {errStatus && (
                        <Typography className="error-msg" variant="p" type="regular" color="primary">
                            {t('common:header:errEmail')}
                        </Typography>
                    )}

                    <Button type="submit" className="login-btn" variant="contained">
                        {t('common:header:login')}
                    </Button>
                </form>
            </div>
        </>
    );

    const renderPhoneOtpLogin = () => (
        <>
            <div className="popup-top">
                <form onSubmit={formikOtp.handleSubmit}>
                    <div className={`${formikOtp.errors.phonenumber ? 'error' : ''} input-wrapper`}>
                        <ImPhone />
                        <TextField
                            id="phonenumber"
                            name="phonenumber"
                            placeholder="Phone Number"
                            type="text"
                            value={formikOtp.values.phonenumber}
                            onChange={formikOtp.handleChange}
                            onBlur={formikOtp.handleBlur}
                            disabled={isRequestOtpSent && !expiredTime}
                        />
                    </div>
                    {formikOtp.touched.phonenumber && formikOtp.errors.phonenumber && (
                        <Typography className="error-msg" variant="p" type="regular" color="primary">
                            {formikOtp.errors.phonenumber}
                        </Typography>
                    )}

                    {errStatus && (
                        <Typography className="error-msg" variant="p" type="regular" color="primary">
                            {errMsg}
                        </Typography>
                    )}

                    {isRequestOtpSent && !expiredTime ? (
                        <>
                            <Grid container spacing={0}>
                                <OtpInput value={otpValue} valueLength={4} onChange={onChangeOtp} />
                            </Grid>
                            <Grid container>
                                <CountdownTimer2 targetDate={otpTimeoutTime} onCompleted={() => setExpiredTime(true)} />
                            </Grid>
                        </>
                    ) : (
                        <Button type="submit" className="login-btn" variant="contained">
                            {t('common:header:requestOtp')}
                        </Button>
                    )}
                </form>
            </div>
        </>
    );

    return (
        <div id="header-inner">
            {/* <div className="row header-top">
                <main style={{ width: '100%' }}>
                    {enablePopupInstallation ? <DesktopInstallApp appName={appName} installMessage={installMessage} /> : null}
                    <TopMenu
                        t={t}
                        isLogin={isLogin}
                        data={customer}
                        handleLogout={handleLogout}
                        app_cookies={app_cookies}
                        storeConfig={storeConfig}
                    />
                </main>
            </div> */}
            <main style={{ width: '100%', maxWidth: 'unset' }}>
                <div className="header-main">
                    <div className="header-middle">
                        <div className="header-middle__left">
                            <div className="box header-middle__logo">
                                <Link href="/" prefetch={false}>
                                    <a>
                                        <Image
                                            className="header-middle__logo-link"
                                            src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                                            alt={storeConfig.default_title}
                                            width={344}
                                            height={120}
                                            storeConfig={storeConfig}
                                            lazy={false}
                                        />
                                    </a>
                                </Link>
                            </div>
                        </div>

                        <div className="header-middle__center">
                            <div className="box">
                                <div className="header-middle__search">
                                    {/* <TextField
                                        id="standard-basic"
                                        label="Search..."
                                        onChange={(e) => setValue(e.target.value)}
                                        onKeyPress={(e) => handleSearch(e)}
                                    /> */}
                                    <Autocomplete
                                        setValue={setValue}
                                        handleSearch={handleSearch}
                                        OptionsItem={OptionAutocomplete}
                                        t={t}
                                        storeConfig={storeConfig}
                                    />
                                    <div className="search-icon">
                                        <IconButton disabled={value === ''} edge="start" onClick={searchByClick} aria-label="close">
                                            <Icon icon="search" size={20} />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            <div className="menu-wrapper">
                                <div className="row menu-category">
                                    <div className="col-xs-12 menu-middle">
                                        {loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}
                                    </div>
                                </div>
                                <div className="header-small__menu">
                                    {loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}
                                </div>
                            </div>
                        </div>

                        <div className={`${isLogin ? 'islogin' : 'notlogin'} header-middle__right`}>
                            <div>
                                {isLogin ? (
                                    <div className="islogin-container">
                                        <div className="islogin-wrapper">
                                            <div className="msg-wrapper">
                                                <TopMenu
                                                    t={t}
                                                    isLogin={isLogin}
                                                    data={customer}
                                                    handleLogout={handleLogout}
                                                    app_cookies={app_cookies}
                                                    storeConfig={storeConfig}
                                                />
                                            </div>

                                            <Button type="button" className="track-order header-btn">
                                                <Link href="/sales/order/track" prefetch={false}>
                                                    <Typography type="bold" variant="span" color="#000000">
                                                        {t('common:menu:trackingorder')}
                                                    </Typography>
                                                </Link>
                                            </Button>
                                        </div>
                                        <div className="header-middle__icons">
                                            <div className="wishlist">
                                                <Link href="/wishlist" prefetch={false}>
                                                    <HiOutlineHeart />
                                                </Link>
                                            </div>
                                            <div className="notification">
                                                <NotificationBell withLink />
                                            </div>
                                            {modules.productcompare.enabled && (
                                                <div className="shopping-bag">
                                                    <ProductCompareIcon withLink isLogin={isLogin} />
                                                </div>
                                            )}
                                            <div id="header-shoppingBag-icon" className="shopping-bag">
                                                <ShoppingBagIcon withLink storeConfig={storeConfig} isLogin={isLogin} />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="islogin-container">
                                        <div className="islogin-wrapper">
                                            <div className="msg-wrapper">
                                                <Button
                                                    type="button"
                                                    className="login-register header-btn"
                                                    startIcon={<FiLogIn />}
                                                    onClick={handlePopup}
                                                >
                                                    <Typography type="bold" variant="span" color="#000000">
                                                        {`${t('common:header:login')} / ${t('common:header:register')}`}
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="header-middle__icons">
                                            {
                                                isLogin ? (
                                                    <>
                                                        <div className="wishlist">
                                                            <Link href="/wishlist" prefetch={false}>
                                                                <HiOutlineHeart />
                                                            </Link>
                                                        </div>
                                                        <div className="notification">
                                                            <NotificationBell withLink />
                                                        </div>
                                                        {modules.productcompare.enabled && (
                                                        <div className="shopping-bag">
                                                            <ProductCompareIcon withLink isLogin={isLogin} />
                                                        </div>
                                            )}
                                                    </>
                                                ) : ''
                                            }
                                            <div id="header-shoppingBag-icon" className="shopping-bag">
                                                <ShoppingBagIcon withLink storeConfig={storeConfig} isLogin={isLogin} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Popper className={open ? 'popup-container' : 'popup-container-hide'} open={open} anchorEl={anchorEl} transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className="popup-wrapper">
                                <div className="arrow-up" />
                                <div className="popup-content-wrapper">
                                    {loginMethod === 'otp' ? renderPhoneOtpLogin() : renderDefaultLogin()}
                                    <div className="popup-bottom">
                                        <Typography type="p" variant="bold" align="center">
                                            {t('common:header:orsign')}
                                        </Typography>
                                        <div className="login-with-phone">
                                            <Button
                                                fullWidth
                                                type="button"
                                                className="switch social-btn"
                                                startIcon={loginMethod === 'otp' ? <HiOutlineLockClosed /> : <ImPhone />}
                                                onClick={switchLogin}
                                            >
                                                <Typography type="bold" variant="span" color="#000000">
                                                    {loginMethod === 'otp' ? t('common:header:password') : t('common:header:phone')}
                                                </Typography>
                                            </Button>
                                        </div>
                                        <div className="social-login-trigger">
                                            <Button
                                                fullWidth
                                                type="button"
                                                className="google social-btn"
                                                startIcon={<ImGoogle />}
                                                onClick={clickGoogle}
                                            >
                                                <Typography type="bold" variant="span" color="#000000">
                                                    {t('common:header:google')}
                                                </Typography>
                                            </Button>

                                            <Button
                                                fullWidth
                                                type="button"
                                                className="facebook social-btn"
                                                startIcon={<ImFacebook />}
                                                onClick={clickFacebook}
                                            >
                                                <Typography type="bold" variant="span" color="#000000">
                                                    {t('common:header:facebook')}
                                                </Typography>
                                            </Button>
                                        </div>
                                        <div className="social-login" hidden>
                                            {firebaseLoaded && firebase.app() && !socialLoginMethodLoading && (
                                                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                                            )}
                                        </div>

                                        <Typography type="p" variant="regular" align="center">
                                            {t('common:header:noaccount')}
                                            <Link href="/customer/account/create" prefetch={false}>
                                                <a className="signup">{t('common:header:signup')}</a>
                                            </Link>
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    )}
                </Popper>
            </main>
            <div className="header-tab">
                <div className="row menu-category">
                    <div className="col-xs-12">{loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}</div>
                </div>
                <div className="header-small__menu">
                    {loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}
                </div>
            </div>
            <style jsx>
                {`
                    .header-main {
                        width: 100%;
                        margin: 0 auto;
                    }
                    .islogin-wrapper {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;
                        column-gap: 10px;
                    }
                    @media (min-width: 768px) {
                        #header-inner {
                            ${
                                storeConfig && storeConfig.pwa && storeConfig.pwa.enabler_sticky_header
                                    ? 'position: fixed;'
                                    : 'position: relative; z-index: 1100;'
                            }
                            width: 100%;
                            background: #fe0000;
                            top: ${showGlobalPromo ? '45px' : '0'};
                            transition: top 1s ease;
                            padding: 0 30px;
                            height: 108px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 100;
                        }
                        #header-inner.header-inner {
                            top: 0px;
                        }
                        .header-middle__center {
                            width: 100%;
                            max-width: calc(100% - 255px);
                        }
                        .header-middle__center .box {
                            width: 100%;
                        }   
                        .row.header-top {
                            max-width: 1366px;
                            margin: 0 auto;
                        }
                        main {
                            max-width: 1366px !important;
                        }
                    }
                    @media (min-width: 1024px) {
                        .header-middle {
                            column-gap: 20px;
                        }
                    }


                    @media (min-width: 1280px) {
                        .header-middle {
                            column-gap: 40px;
                        }

                    }
                    main {
                        background-color: #fe0000;
                    }
                    .header-top {
                        height: 2rem;
                        display: flex;
                        align-items: center;
                        margin: 0;
                    }
                    @media only screen and (max-width: 1023px) and (min-width: 768px) {
                        .header-top {
                            height: unset;
                            padding-top: 0;
                        }
                    }
                    .header-middle {
                        height: 75px;
                        display: flex;
                        justify-content: space-between;
                    }
                    .header-middle__left {
                        height: 100%;
                    }
                    .header-middle__right {
                        width: 100%;
                        max-width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        column-gap: 10px;
                    }
                    .header-middle__right.islogin{
                        max-width: 255px;
                    }
                    .header-middle__right.notlogin{
                        max-width: 175px;
                    }
                    .header-small__menu {
                        display: none;
                    }
                    .header-middle img {
                        width: 100%;
                    }
                    .header-middle__logo-link {
                        cursor: pointer;
                    }
                    .header-middle__icons {
                        float: right;
                        margin: -5px 10px 0 0;    
                        display: flex;
                        align-items: center;
                    }
                    .header-middle__icons > div {
                        margin-right: -5px;
                        margin-left: 0px;
                        display: inline-block;
                    }
                    .search-icon {
                        position: absolute;
                        right: -10px;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        background: #fff;
                        z-index: 9;
                    }
                    .header-middle__search {
                        width: 100%;
                        position: relative;
                        background: #FFFFFF;
                        border-radius: 8px;
                    }
                    .menu-category {
                        width: 100%;
                        display: block;
                        margin: 0;
                    }
                    .menu-category .menu-middle {
                        padding: 0;
                    }
                    .global-promo {
                        height: 45px;
                        border-bottom: 1px solid #d6d6d6;
                        display: flex;
                        align-items: center;
                        padding: 10px 0;
                        margin: 0;
                        background-color: red;
                    }
                    .popup-bottom {
                        text-align: center;
                    }
                    .social-login {
                        margin: 15px 0;
                    }
                    .signup {
                        font-weight: 700;
                        margin-left 5px;
                    }
                    .arrow-up {
                        position: absolute;
                        top: 20px;
                        right: 40px;
                        width: 60px;
                        height: 60px;
                        background: #FFFFFF;
                        border-top-right-radius: 20%;
                        transform: rotate(-50deg) skewX(-10deg) scale(1, 1);
                    }
                    .header_invalid-phonenumber {
                        color: red;
                        font-size: 12px;
                    }
                `}
            </style>
            <style global jsx>
                {`
                    .header-small {
                        top: 0 !important;
                    }
                    .islogin-wrapper > div {
                        width: 100%;
                    }
                    .header-middle__icons svg {
                        font-size: 28px;
                        color: #ffffff !important;
                        margin-left: 20px;
                    }
                    .header-middle__icons .compare svg {
                        transform: scaleX(-1);
                    }
                    .header-middle__search .MuiAutocomplete-root {
                        width: 100% !important;
                        padding: 0 !important;
                    }
                    .header-middle__search .MuiFormControl-root > div {
                        margin: 0;
                        padding-bottom: 0;
                    }
                    .header-middle__search .MuiFormControl-root > div:before {
                        display: none;
                    }
                    .header-middle__search .MuiFormControl-root input {
                        height: 24px;
                        padding: 5px 0 5px 20px !important;
                        font-size: 14px;
                    }
                    .header-middle__search .MuiFormControl-root input::placeholder {
                        opacity: 1;
                    }
                    .search-icon button {
                        padding: 0;
                        margin: 0 7px 0 0;
                    }
                    .search-icon svg {
                        color: #000000;
                        font-size: 20px;
                    }
                    .wishlist:hover {
                        cursor: pointer;
                    }
                    .notification a {
                        margin: 0 !important;
                    }
                    .MuiBadge-root {
                        vertical-align: unset;
                    }
                    .shopping-bag > div {
                        margin: 0;
                    }
                    .shopping-bag .MuiBadge-badge {
                        background-color: #ffe604 !important;
                        color: #000000 !important;
                        font-weight: bold;
                        font-size: 10px;
                        margin-top: 3px;
                        margin-right: 3px;
                    }

                    .msg-wrapper {
                        position: relative;
                        height: 54px;
                    }
                    .msg-wrapper > ul > li {
                        padding: 0;
                        font-size: 12px;
                        font-weight: bold;
                    }
                    .MuiButton-containedPrimary.header-btn {
                        background: white !important;
                        border-radius: 8px;
                        box-shadow: unset;
                        height: 34px;
                        padding: 6px 16px;
                    }
                    .MuiButton-containedPrimary.header-btn.login-register {
                        width: 173px;
                    }
                    .MuiButton-containedPrimary.header-btn.track-order {
                        width: 143px;
                    }
                    .header-btn * {
                        text-transform: uppercase;
                        font-size: 12px;
                        font-family: 'Plus Jakarta Sans Bold', sans-serif;
                    }
                    .MuiButton-containedPrimary.header-btn .MuiButton-label > span {
                        color: black !important;
                        margin: 0;
                    }
                    .MuiButton-containedPrimary.login-register {
                        background: white !important;
                    }
                    .MuiButton-containedPrimary.login-register .MuiButton-label .MuiTypography-root {
                        margin: 0 0 0 10px;
                    }
                    .login-register.header-btn svg {
                        color: black;
                    }
                    .popup-container {
                        z-index: 3;
                        left: -50px !important;
                    }
                    .popup-container-hide {
                        display: none;
                    }
                    .popup-wrapper {
                        position: relative;
                        padding-top: 50px;
                    }
                    .popup-content-wrapper {
                        position: relative;
                        width: 340px;
                        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
                        background: #ffffff;
                        border-radius: 25px;
                        padding: 25px;
                    }
                    .input-wrapper {
                        position: relative;
                        padding: 0 10px 0 42px;
                        border: 1px solid #ececec;
                        border-radius: 10px;
                        margin-top: 5px;
                        margin-bottom: 10px;
                    }
                    .input-wrapper * {
                        margin: 0 !important;
                    }
                    .input-wrapper svg {
                        position: absolute;
                        top: 50%;
                        left: 12px;
                        transform: translateY(-50%);
                        font-size: 22px;
                    }
                    header .MuiInput-underline:before,
                    header .MuiInput-underline:after,
                    .popup-container .MuiInput-underline:before,
                    .popup-container .MuiInput-underline:after {
                        display: none;
                    }
                    .input-wrapper input {
                        padding: 5px;
                        height: 28px;
                        font-size: 14px;
                    }
                    .input-wrapper input:focus {
                        background: transparent;
                    }
                    .forgot-password {
                        width: 100%;
                        text-align: left;
                    }
                    .forgot-password span {
                        display: block;
                        text-transform: initial;
                        font-size: 12px;
                    }
                    .MuiButton-containedPrimary.login-btn {
                        width: 100%;
                        background-color: #ff0000 !important;
                        box-shadow: none;
                        border-radius: 8px;
                        font-size: 14px;
                        padding: 6.75px 16px;
                        margin: 10px 0;
                    }
                    .login-btn span {
                        text-transform: initial;
                    }
                    .popup-bottom p {
                        font-size: 12px;
                    }
                    .social-login-trigger {
                        margin: 15px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        column-gap: 5px;
                    }
                    .social-btn {
                        background-color: #ffffff !important;
                        box-shadow: none;
                        border: 1px solid #ececec;
                        border-radius: 10px;
                        padding: 6px 16px;
                    }
                    .social-btn .MuiButton-label span {
                        color: #000000 !important;
                    }
                    .social-btn .MuiButton-label span.MuiTypography-root {
                        margin: 0;
                    }
                    .msg-wrapper:hover .menu-list {
                        display: block;
                    }
                    .input-wrapper.error {
                        border: 1px solid #ff0000;
                    }
                    .error-msg {
                        color: #ff0000;
                        font-size: 12px;
                    }

                    @media (min-width: 768px) {
                        .header-middle__left,
                        .header-middle__left * {
                            width: 202px;
                            max-height: 70px;
                        }
                        .header-tab {
                            display: none;
                        }
                        #header-nav-menubar {
                            display: flex;
                            justify-content: space-between;
                        }
                        #header-nav-menubar > li > a {
                            padding: 20px 0 18px;
                            font-size: 12px;
                            font-family: 'Plus Jakarta Sans Bold', sans-serif;
                            font-weight: bold;
                        }
                    }
                    @media (min-width: 1024px) {
                    }
                    @media (min-width: 1280px) {
                        .header-middle__left,
                        .header-middle__left * {
                            width: 207px;
                            max-height: 75px;
                        }
                        #header-nav-menubar > li > a {
                            font-size: 12px;
                            font-weight: bold;
                        }
                        .header-small .header-small__menu {
                            display: block;
                        }
                        .header-tab {
                            display: none;
                        }
                        .header-small .menu-category {
                            display: none;
                        }
                        .header-small {
                            height: 108px !important;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default ViewTopNavigation;
